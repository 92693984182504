import { NextPageContext } from "next";
import { deleteCookie, setCookie } from "utils/cookies";
import { SET_IN_COOKIE, DELETE_FROM_COOKIE } from "./types";

export const setItemInCookieAction = (key: string, value: string, expiryInDays?: number, ctx?:NextPageContext) => {
  try {
    setCookie(ctx || null, key, value, null, expiryInDays || 30);
  
    return ({
      type: SET_IN_COOKIE,
      payload: { key, value, isError: false },
    });
  } catch (err) {
    return ({
      type: SET_IN_COOKIE,
      payload: { isError: true },
    })
  }
};

export const removeItemFromCookieAction = (key: string, ctx?:NextPageContext) => {
  try {
    deleteCookie(ctx || null, key);
  
    return ({
      type: DELETE_FROM_COOKIE,
      payload: { key, isError: false },
    });
  } catch(err) {
    return ({
      type: DELETE_FROM_COOKIE,
      payload: { isError: true },
    })
  }
};

